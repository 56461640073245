import { MUIDataTableColumn, MUIDataTableMeta } from 'mui-datatables'
import { StyledActionButtons } from './styles'
import { Delete } from '@material-ui/icons'
import { DriveFileRenameOutline } from '@mui/icons-material'
import { format } from 'date-fns'

interface IColumnsProps {
  onEdit: (id: number) => void
  onDelete: (id: number) => void
}

export function Columns({
  onEdit,
  onDelete,
}: IColumnsProps): MUIDataTableColumn[] {
  return [
    {
      name: 'id',
      label: 'ID',
    },
    {
      name: 'name',
      label: 'Nome',
    },
    {
      name: 'active',
      label: 'Status',
      options: {
        customBodyRender: (value) => (value ? 'Ativo' : 'Desativado'),
      },
    },
    {
      name: 'positioning',
      label: 'Posicionamentos',
    },
    {
      name: 'units',
      label: 'Unidades Cadastradas',
      options: {
        customBodyRender: (value) => value.length,
      },
    },
    {
      name: 'created_at',
      label: 'Data de criação',
      options: {
        customBodyRender: (value) => format(new Date(value), 'dd/MM/yyyy H:mm'),
      },
    },
    {
      name: 'actions',
      label: 'Ações',
      options: {
        setCellProps: () => ({ style: { width: '120px' } }),
        customBodyRender: (_, tableMeta: MUIDataTableMeta) => {
          const id = tableMeta.rowData[0]
          return (
            <StyledActionButtons>
              <button onClick={() => onEdit(id)}>
                <DriveFileRenameOutline />
              </button>
              <button onClick={() => onDelete(id)}>
                <Delete style={{ color: '#e30000' }} />
              </button>
            </StyledActionButtons>
          )
        },
      },
    },
  ]
}
