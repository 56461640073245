import moment from 'moment'
import DeleteIcon from '@mui/icons-material/Delete'
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline'
import { styles } from './styles'
import { convertCentsToLocale } from 'utils/money.formater'

export function TableColumns({ data, onEdit, onDelete }) {
  return [
    {
      name: 'id',
      label: 'ID',
    },
    {
      name: 'code',
      label: 'Código',
    },
    {
      name: 'created_at',
      label: 'Criação',
      options: {
        customBodyRender: (value) => {
          return moment(value).format('DD/MM/YYYY - HH:mm')
        },
      },
    },
    {
      name: 'expiress_at',
      label: 'Expiração',
      options: {
        customBodyRender: (value) => {
          return moment(value).format('DD/MM/YYYY - HH:mm')
        },
      },
    },
    {
      name: 'type',
      label: 'Tipo',
      options: {
        customBodyRender: (value: string) => {
          const types = {
            generic: 'Genêrico',
            products: 'Por Produtos',
            categories: 'Por Categorias',
          }

          return types[value]
        },
      },
    },
    {
      name: 'value_type',
      label: 'Tipo de Valor',
      options: {
        customBodyRender: (value: string) =>
          value === 'percentage' ? 'Porcentagem' : 'Real',
      },
    },
    {
      name: 'value',
      label: 'Valor',
      options: {
        customBodyRender: (value, table) => {
          const { value_type } = data[table.rowIndex]
          return value_type === 'percentage'
            ? `${value}%`
            : `${convertCentsToLocale(value)}`
        },
      },
    },
    {
      name: 'botoes',
      label: 'Ações',
      options: {
        filter: false,
        setCellProps: () => ({ style: { width: '110px' } }),
        customBodyRender: (_, tableMeta: { rowData: any[] }) => {
          const id = tableMeta.rowData[0]
          return (
            <div style={{ textAlign: 'center' }}>
              <button
                type="button"
                onClick={() => onEdit(id)}
                style={styles.btnTable}
              >
                <DriveFileRenameOutlineIcon />
              </button>
              <button
                type="button"
                onClick={() => onDelete(id)}
                style={styles.btnTable}
              >
                <DeleteIcon sx={{ color: '#e30000' }} />
              </button>
            </div>
          )
        },
      },
    },
  ]
}
