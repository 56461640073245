import { useQuery } from '@tanstack/react-query'
import api from 'services/api'

export interface IMarketingCampaign {
  id: number
  name: string
  positioning: string
  image: string
  image_ref: string
  active: number
  units: number[]
  created_at: Date
  updated_at: Date
}

export function useFetchCampaigns() {
  const query = useQuery({
    queryKey: ['/backoffice/marketing-campaigns'],
    queryFn: async () => {
      const response = await api.get<IMarketingCampaign[]>(
        `/backoffice/marketing-campaigns`,
      )
      return response.data
    },
  })

  return { ...query }
}
