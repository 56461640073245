import { Col, Modal, Row, Stack } from 'react-bootstrap'
import TextField from 'Components/Forms/TextField'
import Button from 'Components/Button'
import Select from 'Components/Forms/Select'
import InputMoney from 'Components/Forms/InputMoney'
import { Percent } from '@mui/icons-material'
import MultiSelect from 'Components/Forms/MultiSelect'
import { AsyncMultiSelect } from 'Components/Forms/AsyncMultiSelect'
import { ICupom, useDiscountCuponsModal } from './hooks/useDiscountCuponsModal'
import { useMemo } from 'react'

interface cupomModalProps {
  isOpen: boolean
  onClose: () => void
  cupom?: ICupom | null
}

export function DiscountCupomModal({
  isOpen,
  onClose,
  cupom,
}: cupomModalProps) {
  const {
    units,
    categories,
    isLoadingProductsByName,
    setSearchProductText,
    products,
    control,
    formState,
    handleSubmit,
    onSubmit,
    isLoading,
    selectedType,
    isTypePercentage,
    isEditCupom,
    selectedValueType,
  } = useDiscountCuponsModal({ cupom, onClose })

  const defaultSelectedUnits = useMemo(() => {
    if (cupom?.units && cupom?.units?.length > 0) {
      const selectedUnits = cupom?.units?.map((unit) => {
        const currentUnitInfo = units?.find(
          (unitInfo) => unitInfo.id === Number(unit),
        )

        return {
          label: currentUnitInfo?.nome,
          value: currentUnitInfo?.id,
        }
      })
      return selectedUnits
    }
    return []
  }, [cupom?.units, units])

  const defaultSelectedCategories = useMemo(() => {
    if (cupom?.categories && cupom?.categories?.length > 0) {
      const selectedCategories = cupom?.categories?.map((category) => {
        const currentCategory = categories?.find(
          (categoryInfo) => categoryInfo.id === Number(category),
        )

        return {
          label: currentCategory?.nome,
          value: currentCategory?.id,
        }
      })
      return selectedCategories
    }
    return []
  }, [cupom?.categories, categories])

  return (
    <Modal show={isOpen} onHide={onClose} centered>
      <Modal.Header>
        <Modal.Title>{isEditCupom ? 'Editar' : 'Criar'} Cupom</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <Stack gap={3} direction="vertical">
            <Row>
              <Col>
                <TextField
                  control={control}
                  name="code"
                  label="Código"
                  placeholder="Digite o Código do Cupom"
                  error={formState.errors['code']}
                />
              </Col>
              <Col>
                <Select
                  options={[
                    { label: 'Genérico', value: 'generic' },
                    { label: 'Por Produtos', value: 'products' },
                    { label: 'Por Categorias', value: 'categories' },
                  ]}
                  control={control}
                  name="type"
                  label="Tipo de Desconto"
                  error={formState.errors['type']}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Select
                  options={[
                    { label: 'Porcentagem', value: 'percentage' },
                    { label: 'Real', value: 'real' },
                  ]}
                  control={control}
                  name="value_type"
                  label="Tipo de Valor"
                  error={formState.errors['value_type']}
                />
              </Col>
              {selectedValueType && (
                <Col>
                  {isTypePercentage ? (
                    <TextField
                      control={control}
                      name="value"
                      label="Valor em Porcentagem"
                      endIcon={
                        <Percent style={{ width: '16px', height: '16px' }} />
                      }
                    />
                  ) : (
                    <InputMoney
                      control={control}
                      label="Valor em Reais"
                      name="value"
                      placeholder="00,00"
                      error={formState.errors['value']}
                    />
                  )}
                </Col>
              )}
            </Row>
            <Row>
              <Col>
                <TextField
                  control={control}
                  label="Data de Expiração"
                  name="expiress_at"
                  type="datetime-local"
                />
              </Col>
            </Row>
            <Row>
              <MultiSelect
                control={control}
                label="Unidades"
                name="units"
                options={units.map((unit) => ({
                  value: unit.id,
                  label: unit.nome,
                }))}
                placeholder="Selecione uma ou mais Unidades"
                defaultValues={defaultSelectedUnits}
              />
            </Row>
            {selectedType && selectedType !== 'generic' && (
              <Row>
                {selectedType === 'products' ? (
                  <AsyncMultiSelect
                    control={control}
                    label="Produtos"
                    name="products"
                    placeholder="Selecione uma ou mais Produtos"
                    onInputChange={(value: string) =>
                      setSearchProductText(value)
                    }
                    isLoading={isLoadingProductsByName}
                    options={products}
                  />
                ) : (
                  <MultiSelect
                    control={control}
                    label="Categorias"
                    name="categories"
                    options={categories.map((category) => ({
                      value: Number(category.id),
                      label: String(category.nome),
                    }))}
                    placeholder="Selecione uma ou mais Categorias"
                    defaultValues={defaultSelectedCategories}
                  />
                )}
              </Row>
            )}
            {/* <Row>
              <Col>
                <Select
                  options={[
                    { label: 'Todos', value: ['MODAL', 'BANNER'] },
                    { label: 'Modal', value: ['MODAL'] },
                    { label: 'Banner', value: ['BANNER'] },
                  ]}
                  control={control}
                  name="positions"
                  label="Posicionamentos"
                  error={formState.errors['positions'] as FieldError}
                />
              </Col>
            </Row> */}
          </Stack>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onClose} disabled={isLoading}>
            Cancelar
          </Button>
          <Button
            type="submit"
            color="green"
            disabled={isLoading}
            loading={isLoading}
          >
            Salvar
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}
