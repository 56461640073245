import api from 'services/api'
import { DiscountCupomDto } from '../validators'

export const CuponsService = {
  create: async (data: DiscountCupomDto) => {
    try {
      const response = await api.post(`/backoffice/discount-cupons`, data)
      return response.data
    } catch (error) {
      console.log(error)
      throw new Error('Houve um erro ao criar o cupom')
    }
  },
  edit: async (id: number, data: DiscountCupomDto) => {
    try {
      const response = await api.patch(
        `/backoffice/discount-cupons/${id}`,
        data,
      )
      return response.data
    } catch (error) {
      console.log(error)
      throw new Error('Houve um erro ao editar o cupom')
    }
  },
  delete: async (id: number) => {
    try {
      const response = await api.delete(`/backoffice/discount-cupons/${id}`)
      return response.data
    } catch (error) {
      console.log(error)
      throw new Error('Houve um erro ao excluir o cupom')
    }
  },
}
