import Button from 'Components/Button'
import { Modal } from 'react-bootstrap'

interface IDeleteCupomModalProps {
  isOpen: boolean
  onClose: () => void
  onDelete: () => void
  isLoading?: boolean
}

export function DeleteCupomModal({
  isOpen,
  onClose,
  onDelete,
  isLoading,
}: IDeleteCupomModalProps) {
  return (
    <Modal show={isOpen} onHide={onClose} open centered>
      <Modal.Header closeButton>
        <Modal.Title>Deseja excluir esse Cupom?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Tem certeza que deseja excluir o cupom? Essa ação é irreversível
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={isLoading} onClick={onClose}>
          Cancelar
        </Button>
        <Button disabled={isLoading} color="error" onClick={onDelete}>
          Deletar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
