import { type InputHTMLAttributes } from 'react'
import Select, { MultiValue } from 'react-select'
import { Form } from 'react-bootstrap'
import {
  type Control,
  type FieldError,
  Path,
  useController,
} from 'react-hook-form'
import { multiSelectStyles, StyledError } from './styles'

interface TextFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  name: Path<any>
  control: Control<any, any>
  label: string
  error?: FieldError | null
  options: { value: string | number; label: string }[]
  placeholder: string
  defaultValues?: any
}

export default function MultiSelect({
  name,
  control,
  label,
  options,
  placeholder,
  defaultValues = [],
}: TextFieldProps) {
  const {
    field: { onChange, onBlur },
    fieldState: { error },
  } = useController({
    name,
    control,
  })
  const handleChange = (
    values: MultiValue<{
      [key: string]: string | number
    }>,
  ) => {
    const parsedValues = values.map((option) => option.value)
    onChange(parsedValues)
    onBlur()
  }

  const styles = multiSelectStyles(Boolean(error?.message))

  return (
    <Form.Group>
      {!!label && <Form.Label htmlFor={name}>{label}</Form.Label>}
      <Select
        name={name}
        options={options}
        onChange={handleChange}
        menuPosition="fixed"
        isMulti
        placeholder={placeholder}
        closeMenuOnSelect={false}
        noOptionsMessage={() => 'Nenhuma opção encontrada'}
        defaultValue={defaultValues}
        styles={styles}
      />
      {!!error && <StyledError>{error.message}</StyledError>}
    </Form.Group>
  )
}
