import { useMutation } from '@tanstack/react-query'
import { MarketingCampaignService } from '../services'
import { queryClient } from 'lib/queryClient'
import { toast } from 'react-toastify'

interface IUseDeleteCampaignProps {
  id: number
  setId: (id: null | number) => void
}

export function useDeleteCampaign({ id, setId }: IUseDeleteCampaignProps) {
  const mutateDeleteCampaign = useMutation({
    mutationFn: () => MarketingCampaignService.delete(id as number),
    onSuccess: () => {
      queryClient.invalidateQueries(['/backoffice/marketing-campaigns'])
      setId(null)
      toast.success('Campanha excluída com sucesso!')
    },
    onError: () => toast.error('Erro ao excluir a Campanha.'),
  })

  return { ...mutateDeleteCampaign }
}
