import * as yup from 'yup'

export const cupomFormSchema = yup.object({
  code: yup
    .string()
    .min(6, 'Pelo menos 6 caracteres')
    .required('Digite o Código do Cupom.'),
  type: yup.string().required('Escolha o Tipo do Cupom.'),
  value_type: yup.string().required('Escolha o Tipo de Valor.'),
  value: yup.string().required('Preencha o Valor.'),
  units: yup
    .array()
    .of(yup.string())
    .required('Campo Obrigatório')
    .min(1, 'Pelo menos uma unidade deve ser selecionada'),
  products: yup
    .array()
    .nullable()
    .when('type', {
      is: (value: string) => {
        return value === 'products'
      },
      then: () =>
        yup
          .array()
          .of(yup.string())
          .min(1, 'Pelo menos um produto deve ser selecionado')
          .required('Campo Obrigatório'),
      otherwise: (s) => s,
    }),
  categories: yup
    .array()
    .nullable()
    .when('type', {
      is: (value: string) => {
        return value === 'categories'
      },
      then: () =>
        yup
          .array()
          .of(yup.string())
          .min(1, 'Pelo menos uma categoria deve ser selecionada')
          .required('Campo Obrigatório'),
      otherwise: (s) => s,
    }),
  expiress_at: yup.string().required('Preencha a Data de Expiração'),
})

export type CreateCupomSchemaType = yup.InferType<typeof cupomFormSchema>

export interface DiscountCupomDto {
  code: string
  type: string
  value_type: string
  value: string
  units: string
  products: string | null
  categories: string | null
  expiress_at: string
}
