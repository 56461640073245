export const MESSAGE_TYPES = [
  {
    type: 'pending_purchase',
    name: 'Compra pendente',
  },
  {
    type: 'opened_cart',
    name: 'Carrinho aberto',
  },
]
