import { useMemo } from 'react'
import { Switch } from '@mui/material'
import { Col, Form, Modal, Row, Stack } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import Button from 'Components/Button'
import MultiSelect from 'Components/Forms/MultiSelect'
import Select from 'Components/Forms/Select'
import TextField from 'Components/Forms/TextField'
import { useGlobalInfos } from 'hooks/useGlobalInfos'
import { IMarketingCampaign } from 'pages/MarketingCampaigns/hooks/useFetchCampaigns'
import basket from '../../../../assets/images/basketIcon.png'
import { useMutation } from '@tanstack/react-query'
import {
  MarketingCampaignDto,
  MarketingCampaignService,
} from 'pages/MarketingCampaigns/services'
import { queryClient } from 'lib/queryClient'
import { toast } from 'react-toastify'

interface ICampaignModalProps {
  isOpen: boolean
  onClose: () => void
  marketingCampaign?: IMarketingCampaign | null
}

export function CampaignModal({
  isOpen,
  marketingCampaign = null,
  onClose,
}: ICampaignModalProps) {
  const { units } = useGlobalInfos()
  const { control, watch, setValue, formState, handleSubmit } = useForm({
    defaultValues: {
      name: marketingCampaign?.name || '',
      positioning: marketingCampaign?.positioning || null,
      units: marketingCampaign?.units || [],
      active: marketingCampaign?.active || 0,
      image: marketingCampaign?.image || '',
      image_ref: marketingCampaign?.image_ref || '',
      file: null,
    },
  })

  const mutateCampaign = useMutation({
    mutationFn: (data: MarketingCampaignDto) => {
      return marketingCampaign
        ? MarketingCampaignService.edit(marketingCampaign.id, data)
        : MarketingCampaignService.create(data)
    },
  })

  async function onSubmit(data: MarketingCampaignDto) {
    mutateCampaign.mutate(data, {
      onSuccess: () => {
        queryClient.invalidateQueries(['/backoffice/marketing-campaigns'])
        onClose()
        const message = marketingCampaign
          ? 'Campanha editada com sucesso!'
          : 'Campanha criada com sucesso!'
        toast.success(message)
      },
      onError: () => {
        const message = marketingCampaign
          ? 'Erro ao editar a Campanha!'
          : 'Erro ao criar a Campanha!'
        toast.error(message)
      },
    })
  }

  const defaultSelectedUnits = useMemo(() => {
    if (marketingCampaign?.units && marketingCampaign?.units?.length > 0) {
      const selectedUnits = marketingCampaign?.units?.map((unit) => {
        const currentUnitInfo = units?.find(
          (unitInfo) => unitInfo.id === Number(unit),
        )

        return {
          label: currentUnitInfo?.nome,
          value: currentUnitInfo?.id,
        }
      })
      return selectedUnits
    }
    return []
  }, [marketingCampaign?.units, units])

  const newImageField = watch('file')

  function handleUploadImage() {
    const inputFileEllement = document.createElement('input')
    inputFileEllement.setAttribute('type', 'file')
    inputFileEllement.setAttribute('accept', 'image/*')

    inputFileEllement.click()

    inputFileEllement.addEventListener('change', (event: any) => {
      const file = event.target.files[0]
      if (file) {
        setValue('file', file)
      }
    })
  }

  const IMAGE = useMemo(() => {
    if (newImageField) {
      return URL.createObjectURL(newImageField)
    }

    if (marketingCampaign?.image) return marketingCampaign?.image

    return basket
  }, [marketingCampaign?.image, newImageField])

  return (
    <Modal show={isOpen} onHide={onClose} centered>
      <Modal.Header>
        <Modal.Title>
          {marketingCampaign ? 'Editar' : 'Criar'} Campanha de Marketing
        </Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <Stack gap={3} direction="vertical">
            <Row>
              <Col>
                <TextField
                  control={control}
                  name="name"
                  label="Nome da Campanha"
                  placeholder="Digite o nome da campanha"
                  error={formState.errors['name']}
                />
              </Col>
              <Col>
                <Select
                  options={[
                    { label: 'Modal', value: 'MODAL' },
                    { label: 'Banner', value: 'BANNER' },
                    { label: 'Todos posicionamentos', value: 'ALL' },
                  ]}
                  control={control}
                  name="positioning"
                  label="Posicionamentos"
                  error={formState.errors['positioning']}
                />
              </Col>
            </Row>
            <Row>
              <MultiSelect
                control={control}
                label="Unidades"
                name="units"
                options={units.map((unit) => ({
                  value: unit.id,
                  label: unit.nome,
                }))}
                placeholder="Selecione uma ou mais Unidades"
                defaultValues={defaultSelectedUnits}
              />
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Status</Form.Label>
                  <Switch
                    name="active"
                    defaultChecked={Boolean(marketingCampaign?.active)}
                    onChange={(event) =>
                      setValue('active', event.target.checked ? 1 : 0)
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <div>
                <img src={IMAGE} style={{ width: 'auto', maxWidth: '150px' }} />
                <Button onClick={handleUploadImage}>Alterar foto</Button>
              </div>
            </Row>
          </Stack>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onClose} disabled={mutateCampaign.isLoading}>
            Cancelar
          </Button>
          <Button
            type="submit"
            color="green"
            disabled={mutateCampaign.isLoading}
            loading={mutateCampaign.isLoading}
          >
            Salvar
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}
