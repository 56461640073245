import api from 'services/api'

export interface MarketingCampaignDto {
  name: string
  positioning: string | null
  image: string
  image_ref: string
  active: number
  units: number[]
  file: null | Blob
}

export const MarketingCampaignService = {
  create: async (campaign: MarketingCampaignDto) => {
    const formData = new FormData()

    formData.append('name', campaign.name)
    formData.append('positioning', campaign.positioning ?? '')
    formData.append('image', campaign.image)
    formData.append('image_ref', campaign.image_ref)
    formData.append('active', String(campaign.active))
    formData.append('units', JSON.stringify(campaign.units))
    formData.append('file', campaign.file ?? '')

    try {
      await api.post(`/backoffice/marketing-campaigns`, formData)
    } catch (error) {
      console.log(error)
    }
  },

  edit: async (id: number, campaign: MarketingCampaignDto) => {
    const formData = new FormData()
    formData.append('name', campaign.name)
    formData.append('positioning', campaign.positioning ?? '')
    formData.append('image', campaign.image)
    formData.append('image_ref', campaign.image_ref)
    formData.append('active', String(campaign.active))
    formData.append('units', JSON.stringify(campaign.units))
    formData.append('file', campaign.file ?? '')

    try {
      await api.patch(`/backoffice/marketing-campaigns/${id}`, formData)
    } catch (error) {
      console.log(error)
    }
  },
  delete: async (id: number) => {
    try {
      await api.delete(`/backoffice/marketing-campaigns/${id}`)
    } catch (error) {
      console.log(error)
    }
  },
}
