import Table from 'Components/Table'
import * as S from './styles'
import {
  IMarketingCampaign,
  useFetchCampaigns,
} from './hooks/useFetchCampaigns'
import { Spinner } from 'react-bootstrap'
import { textLabels } from 'configs/MuiTableOptionsTextLabels'
import { MUIDataTableOptions } from 'mui-datatables'
import { Columns } from './components/Columns'
import { useState } from 'react'
import { DeleteCampaignModal } from './components/DeleteCampaignModal'
import { useDeleteCampaign } from './hooks/useDeleteCampaign'
import { CampaignModal } from './components/CampaignModal'
import Button from 'Components/Button'

export function MarketingCampaigns() {
  const [campaignToDelete, setCampaignToDelete] = useState<number | null>(null)
  const [selectedCampaign, setSelectedCampaign] =
    useState<IMarketingCampaign | null>(null)
  const [isOpenCampaignModal, setIsOpenCampaignModal] = useState(false)
  const { data, isLoading } = useFetchCampaigns()
  const { mutate: onDeleteCampaign } = useDeleteCampaign({
    id: campaignToDelete as number,
    setId: setCampaignToDelete,
  })

  const options: MUIDataTableOptions = {
    viewColumns: false,
    filter: false,
    customToolbar: () => (
      <Button onClick={() => setIsOpenCampaignModal(true)}>
        Criar Campanha
      </Button>
    ),
    count: data?.length ?? 0,
    textLabels: {
      ...textLabels,
      body: {
        noMatch: isLoading ? <Spinner /> : 'Ops... Nenhum registro encontrado.',
      },
    },
  }

  const columns = Columns({
    onEdit: (id) => onEditCampaign(id),
    onDelete: (id: number) => setCampaignToDelete(id),
  })

  function onEditCampaign(id: number) {
    const currentCampaignToEdit = data?.find((campaign) => campaign.id === id)
    setSelectedCampaign(currentCampaignToEdit || null)
    setIsOpenCampaignModal(true)
  }

  return (
    <>
      <S.TableWrapper>
        <Table
          columns={columns}
          data={isLoading || !data?.length ? [] : data}
          options={options}
          showSearchComponent={false}
        />
      </S.TableWrapper>

      {Boolean(campaignToDelete) && (
        <DeleteCampaignModal
          isOpen={Boolean(campaignToDelete)}
          onClose={() => setCampaignToDelete(null)}
          onDelete={onDeleteCampaign}
          isLoading={false}
        />
      )}

      {Boolean(isOpenCampaignModal) && (
        <CampaignModal
          isOpen={Boolean(isOpenCampaignModal)}
          onClose={() => {
            setSelectedCampaign(null)
            setIsOpenCampaignModal(false)
          }}
          marketingCampaign={selectedCampaign}
        />
      )}
    </>
  )
}
