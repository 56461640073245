export const styles = {
  tableText: {
    fontSize: 14,
  },
  btnTable: {
    border: 'none',
    background: 'none',
    paddingTop: '5px',
  },
}
