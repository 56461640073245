import { Spinner } from 'react-bootstrap'
import { textLabels } from '../../configs/MuiTableOptionsTextLabels'
import { MUIDataTableOptions } from 'mui-datatables'
import Table from 'Components/Table'
import { useMutation, useQuery } from '@tanstack/react-query'
import api from 'services/api'
import { TableColumns } from './components/Columns'
import * as S from './styles'
import Button from 'Components/Button'
import { useState } from 'react'
import { DeleteCupomModal } from './components/DeleteModal'
import { DiscountCupomModal } from './components/DiscountCupons'
import { format } from 'date-fns'
import { CuponsService } from './services'
import { queryClient } from 'lib/queryClient'
import { toast } from 'react-toastify'

export function DiscountCupons() {
  const [cupomToDelete, setCupomToDelete] = useState<number | null>(null)
  const [isOpenCupomModal, setIsOpenCupomModal] = useState(false)
  const [selectedCupom, setSelectedCupom] = useState(null)

  const { data, isLoading } = useQuery({
    queryKey: ['/backoffice/cupoms'],
    queryFn: async () => {
      const response = await api.get(`/backoffice/discount-cupons`)
      return response.data
    },
  })

  const mutateDeleteCupom = useMutation({
    mutationFn: () => CuponsService.delete(cupomToDelete as number),
    onSuccess: () => {
      queryClient.invalidateQueries(['/backoffice/cupoms'])
      setCupomToDelete(null)
      toast.success('Cupom excluído com sucesso!')
    },
    onError: () => toast.error('Erro ao excluir o Cupom.'),
  })

  const options: MUIDataTableOptions = {
    viewColumns: false,
    serverSide: true,
    customToolbar: () => (
      <Button onClick={() => setIsOpenCupomModal(true)}>Criar Cupom</Button>
    ),
    count: data?.length ?? 0,
    textLabels: {
      ...textLabels,
      body: {
        noMatch: isLoading ? <Spinner /> : 'Ops... Nenhum registro encontrado.',
      },
    },
  }

  function onOpenEditCupomModal(id: number) {
    const currentCupomToEdit = data.find((cupom) => cupom?.id === id)
    setIsOpenCupomModal(true)
    setSelectedCupom({
      ...currentCupomToEdit,
      expiress_at: format(
        new Date(currentCupomToEdit.expiress_at),
        'yyyy-MM-dd HH:mm:ss',
      ),
      units: currentCupomToEdit?.units?.map((item: string) => Number(item)),
      // criar no back depois um retorno a mais para os produtos selecionados
      // trazendo id e nome para preencher como default value
      products: currentCupomToEdit?.products?.map((item: string) =>
        Number(item),
      ),
      categories: currentCupomToEdit?.categories?.map((item: string) =>
        Number(item),
      ),
    })
  }

  const columns = TableColumns({
    data,
    onEdit: onOpenEditCupomModal,
    onDelete: (id: number) => setCupomToDelete(id),
  })

  return (
    <>
      <S.TableWrapper>
        <Table
          columns={columns}
          data={isLoading ? [] : data}
          options={options}
          showSearchComponent={false}
        />
      </S.TableWrapper>

      {Boolean(cupomToDelete) && (
        <DeleteCupomModal
          isOpen={Boolean(cupomToDelete)}
          onClose={() => setCupomToDelete(null)}
          onDelete={mutateDeleteCupom.mutate}
          isLoading={mutateDeleteCupom.isLoading}
        />
      )}

      {isOpenCupomModal && (
        <DiscountCupomModal
          isOpen={isOpenCupomModal}
          onClose={() => {
            setIsOpenCupomModal(false)
            setSelectedCupom(null)
          }}
          cupom={selectedCupom}
        />
      )}
    </>
  )
}
