import { InputHTMLAttributes } from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import { Control, Controller, FieldError, Path } from 'react-hook-form'
import ReactInputMask from 'react-input-mask'

interface InputMoneyProps extends InputHTMLAttributes<HTMLInputElement> {
  name: Path<any>
  control: Control<any, any>
  error: FieldError | undefined
  disabled?: boolean
  maskChar?: string | null
  mask: string
  label: string
  endIcon?: JSX.Element | null
}

export default function InputMask({
  name,
  control,
  disabled = false,
  maskChar = '',
  mask,
  error,
  label,
  endIcon: EndIcon = null,
  ...rest
}: InputMoneyProps) {
  return (
    <Form.Group>
      {!!label && <Form.Label>{label}</Form.Label>}
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <ReactInputMask
            mask={mask}
            alwaysShowMask={false}
            disabled={disabled}
            maskChar={maskChar}
            {...field}
            {...rest}
          >
            {(maskInputProps: Record<string, any>) => (
              <>
                <Form.Control
                  {...maskInputProps}
                  isInvalid={!!error}
                  type="text"
                  size="sm"
                />
                {EndIcon && (
                  <InputGroup.Text
                    style={{
                      padding: '5px',
                      border: error ? '1px solid #dc3545' : '1px solid #dee2e6',
                    }}
                  >
                    {EndIcon}
                  </InputGroup.Text>
                )}
              </>
            )}
          </ReactInputMask>
        )}
      />
      {!!error && (
        <Form.Control.Feedback type="invalid">
          {error.message}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  )
}
