import { InputHTMLAttributes } from 'react'
import AsyncSelect, { MultiValue } from 'react-select'
import {
  type Control,
  type FieldError,
  Path,
  useController,
} from 'react-hook-form'
import { multiSelectStyles, StyledError } from './styles'
import { Form } from 'react-bootstrap'

interface AsyncMultiSelectProps extends InputHTMLAttributes<HTMLInputElement> {
  name: Path<any>
  control: Control<any, any>
  label: string
  error?: FieldError | null
  placeholder: string
  defaultValues?: any
  onInputChange?: (value: string) => void
  isLoading?: boolean
  options: { value: string | number; label: string }[]
}

export function AsyncMultiSelect({
  name,
  control,
  label,
  placeholder,
  options = [],
  defaultValues = [],
  onInputChange,
  isLoading = false,
}: AsyncMultiSelectProps) {
  const {
    field: { onChange, onBlur },
    fieldState: { error },
  } = useController({
    name,
    control,
  })
  const handleChange = (
    values: MultiValue<{
      [key: string]: string | number
    }>,
  ) => {
    const parsedValues = values.map((option) => option.value)
    onChange(parsedValues)
    onBlur()
  }

  const styles = multiSelectStyles(Boolean(error?.message))
  return (
    <Form.Group>
      {!!label && <Form.Label htmlFor={name}>{label}</Form.Label>}
      <AsyncSelect
        isMulti
        name={name}
        onInputChange={onInputChange}
        onChange={handleChange}
        options={options}
        menuPosition="fixed"
        placeholder={placeholder}
        closeMenuOnSelect={false}
        noOptionsMessage={() => 'Nenhuma opção encontrada'}
        defaultValue={defaultValues}
        isLoading={isLoading}
        loadingMessage={() => 'Carregando informações...'}
        styles={styles}
      />
      {!!error && <StyledError>{error.message}</StyledError>}
    </Form.Group>
  )
}
