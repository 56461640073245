import { CuponsService } from 'pages/DiscountCupons/services'
import { toast } from 'react-toastify'
import { useGlobalInfos } from 'hooks/useGlobalInfos'
import { useCallback, useEffect, useState } from 'react'
import { getAllProductsByName } from 'pages/Products/services'
import {
  CreateCupomSchemaType,
  cupomFormSchema,
  DiscountCupomDto,
} from 'pages/DiscountCupons/validators'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation } from '@tanstack/react-query'
import { queryClient } from 'lib/queryClient'

export interface ICupom {
  code: string
  expiress_at: string
  type: string
  value: string
  id: number
  units: string[]
  products: string[]
  categories: string[]
}

interface IOptions {
  label: string
  value: number
}

interface IUseDiscountCuponsModal {
  cupom: ICupom | null | undefined
  onClose: () => void
}

export function useDiscountCuponsModal({
  cupom,
  onClose,
}: IUseDiscountCuponsModal) {
  const { units } = useGlobalInfos()
  const { categories } = useGlobalInfos()
  const [isLoadingProductsByName, setIsLoadingProductsByName] = useState(false)
  const [searchProductText, setSearchProductText] = useState('')
  const [products, setProducts] = useState<IOptions[]>([])

  const getProdyctByName = useCallback(async (name: string) => {
    setIsLoadingProductsByName(true)
    try {
      const response = await getAllProductsByName(name)
      setProducts(
        response.map((product) => ({ label: product.name, value: product.id })),
      )
      setIsLoadingProductsByName(false)
    } catch (error: any) {
      setIsLoadingProductsByName(false)
      return toast.error(error?.message)
    }
  }, [])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchProductText) {
        getProdyctByName(searchProductText)
      }
    }, 800)

    return () => clearTimeout(delayDebounceFn)
  }, [searchProductText, getProdyctByName])

  const { control, formState, watch, handleSubmit } =
    useForm<CreateCupomSchemaType>({
      mode: 'onBlur',
      reValidateMode: 'onBlur',
      defaultValues: cupom ?? {},
      resolver: yupResolver(cupomFormSchema),
    })

  const mutateCupom = useMutation({
    mutationFn: (data: DiscountCupomDto) => {
      return cupom
        ? CuponsService.edit(cupom.id, data)
        : CuponsService.create(data)
    },
  })

  async function onSubmit(data: CreateCupomSchemaType) {
    const payload = {
      ...data,
      units: JSON.stringify(data.units),
      products: JSON.stringify(data.products) ?? null,
      categories: JSON.stringify(data.categories) ?? null,
    }

    mutateCupom.mutate(payload, {
      onSuccess: () => {
        queryClient.invalidateQueries(['/backoffice/cupoms'])
        onClose()
        const message = cupom
          ? 'Cupom editado com sucesso!'
          : 'Cupom criado com sucesso!'
        toast.success(message)
      },
      onError: () => {
        const message = cupom
          ? 'Erro ao editar o Cupom!'
          : 'Erro ao criar o Cupom!'
        toast.error(message)
      },
    })
  }

  const isLoading = mutateCupom.isLoading || formState.isSubmitting

  const selectedType = watch('type')
  const selectedValueType = watch('value_type')
  const isTypePercentage = selectedValueType === 'percentage'

  const isEditCupom = !!cupom

  return {
    units,
    categories,
    isLoadingProductsByName,
    setSearchProductText,
    products,
    control,
    formState,
    handleSubmit,
    onSubmit,
    isLoading,
    selectedType,
    isTypePercentage,
    isEditCupom,
    selectedValueType,
  }
}
