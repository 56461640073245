import React, { useState, useEffect } from 'react'
import { Form, Col, Button, Spinner, Row } from 'react-bootstrap'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import { Formik } from 'formik'
import api from '../../services/api'
import { MESSAGE_TYPES } from './constants'
import { Switch } from '@mui/material'

export default function NewNotification() {
  const [units, setUnits] = useState([])
  const [selectedUnits, setSelectedUnits] = useState([])
  const [loading, setLoading] = useState(false)
  const [sendInWhatsapp, setSendInWhatsapp] = useState(false)
  const IS_ALL_SELECTED =
    selectedUnits.length > 0 && selectedUnits.length === units.length

  const schema = yup.object({
    type: yup.string().required(),
    title: yup
      .string()
      .min(5)
      .notRequired()
      .when('type', (type, field) =>
        type === 'custom' ? field.required() : field,
      ),
    message: yup
      .string()
      .min(5)
      .notRequired()
      .when('type', (type, field) =>
        type === 'custom' ? field.required() : field,
      ),
    send_in_whatsapp: yup.boolean().notRequired().default(false),
  })

  useEffect(() => {
    function getUnits() {
      setLoading(true)
      api.get('/units').then((response) => {
        setUnits(
          response.data.map((unit) => ({ id: unit.id, nome: unit.nome })),
        )
        setLoading(false)
      })
    }

    getUnits()
  }, [])

  const handleChange = (event) => {
    const CURRENT_VALUE = Number(event.target.value)
    const ALREADY_CHECKED =
      selectedUnits.findIndex((value) => value === CURRENT_VALUE) !== -1

    const units = ALREADY_CHECKED
      ? selectedUnits.filter((item) => item !== CURRENT_VALUE)
      : [...selectedUnits, CURRENT_VALUE]
    setSelectedUnits(units)
  }

  const selectAll = () =>
    setSelectedUnits(
      selectedUnits.length === units.length ? [] : units.map((unit) => unit.id),
    )

  return (
    <div>
      <h2>Enviar Notificação</h2>
      <h5>Selecione as Unidades que receberão a notificação:</h5>
      <div className="form-check">
        <input
          type="checkbox"
          className="form-check-input"
          id="select-all"
          checked={IS_ALL_SELECTED}
          onChange={selectAll}
        />
        <label className="form-check-label" htmlFor="select-all">
          Selecionar tudo
        </label>
        {loading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <Spinner animation="border" />
          </div>
        ) : (
          units.map((unit) => (
            <div
              className="form-check"
              style={{ marginTop: '5px' }}
              key={unit.id}
            >
              <input
                type="checkbox"
                className="form-check-input"
                name={unit.id}
                id={unit.id}
                onChange={handleChange}
                value={unit.id}
                checked={
                  selectedUnits.findIndex(
                    (value) => value === Number(unit.id),
                  ) !== -1
                }
              />
              <label className="form-check-label" htmlFor={unit.id}>
                {unit.nome}
              </label>
            </div>
          ))
        )}
        <div style={{ marginTop: '10px' }}>
          <label htmlFor="send_in_whatsapp">Enviar no Whatsapp</label>
          <Switch
            name="send_in_whatsapp"
            onChange={(event) => setSendInWhatsapp(event.target.checked)}
          />
        </div>
      </div>

      <div style={{ marginTop: '20px' }}>
        <h5>Digite os dados da Notificação:</h5>
        <Formik
          enableReinitialize
          validationSchema={schema}
          onSubmit={async (values, { resetForm }) => {
            if (selectedUnits.length === 0) {
              toast.error(
                `É necessário escolher as unidades para envio das notificações.`,
              )
              return
            }
            const payload = {
              ...values,
              units: selectedUnits,
              send_in_whatsapp: sendInWhatsapp,
            }
            try {
              const response = await api.post('/notification', payload)
              resetForm()
              toast.success(`${response.data.title}. ${response.data.message}`)
            } catch (error) {
              console.log(error)
              toast.error(
                `Houve um erro ao fazer o envio das notificações. Tente novamente.`,
              )
            }
          }}
          initialValues={{}}
        >
          {({
            handleSubmit,
            handleChange,
            resetForm,
            values,
            touched,
            isValid,
            errors,
            isSubmitting,
          }) => (
            <Form noValidate id="categoryForm" onSubmit={handleSubmit}>
              <Row>
                <Form.Group as={Col} md="12" controlId="typeValidation">
                  <Form.Label>Tipo de mensagem</Form.Label>
                  <Form.Control
                    placeholder="ex: Venha ver as promoções!"
                    as="select"
                    name="type"
                    value={values.type || ''}
                    onChange={handleChange}
                    isInvalid={!!errors.type}
                    isValid={touched.type && !errors.type}
                  >
                    <option value="">Selecione o tipo da mensagem</option>
                    {MESSAGE_TYPES.map((message) => (
                      <option value={message.type} key={message.type}>
                        {message.name}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    É necessário escolher o tipo de mensagem
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              {values.type === 'generic' && (
                <Row>
                  <Form.Group as={Col} md="12" controlId="titleValidation">
                    <Form.Label>Título da Notificação</Form.Label>
                    <Form.Control
                      placeholder="ex: Venha ver as promoções!"
                      type="text"
                      name="title"
                      value={values.title}
                      onChange={handleChange}
                      isInvalid={!!errors.title}
                      isValid={touched.title && !errors.title}
                    />
                    <Form.Control.Feedback type="invalid">
                      É necessário preencher o titulo da mensagem
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="12" controlId="messageValidation">
                    <Form.Label>Mensagem da Notificação</Form.Label>
                    <Form.Control
                      name="message"
                      as="textarea"
                      rows={3}
                      placeholder="ex: Mais de 80% de desconto em vários produtos"
                      value={values.message}
                      onChange={handleChange}
                      isInvalid={!!errors.message}
                      isValid={touched.message && !errors.message}
                    />
                    <Form.Control.Feedback type="invalid">
                      A mensagem é um campo obrigatório!
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
              )}
              <div style={{ marginTop: '12px' }}>
                <Form.Group>
                  <Form.Check
                    checked={values.send_in_whatsapp}
                    value={values.send_in_whatsapp}
                    type="checkbox"
                    label="Enviar no Whatsapp"
                    name="send_in_whatsapp"
                    onChange={handleChange}
                  />
                </Form.Group>
              </div>
              <div
                style={{
                  marginTop: '20px',
                  display: 'flex',
                  alignItems: 'flex-end',
                  justifyContent: 'flex-end',
                }}
              >
                <Button
                  form="categoryForm"
                  type="reset"
                  onClick={resetForm}
                  variant="custom"
                  disabled={isSubmitting | !isValid}
                >
                  Limpar
                </Button>
                <Button
                  form="categoryForm"
                  type="submit"
                  variant="success"
                  disabled={isSubmitting | !isValid}
                >
                  {isSubmitting ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    'Enviar'
                  )}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}
