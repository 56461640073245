import styled from 'styled-components'

export const multiSelectStyles = (error: boolean) => {
  return {
    placeholder: (styles) => ({ ...styles, fontSize: '0.875rem' }),
    input: (styles) => ({
      ...styles,
      padding: '0',
    }),
    control: (base) => ({
      ...base,
      border: error ? '1px solid #dc3545' : '1px solid #dee2e6',
      boxShadow: 'none',
      '&:hover': {
        border: error ? '1px solid #dc3545' : '1px solid #dee2e6',
      },
      '&:focus-within': {
        border: error ? '1px solid #dc3545' : '1px solid #86b7fe',
        outline: 'none',
        boxShadow: error ? 'none' : '0 0 0 0.25rem rgba(13,110,253,.25)',
      },
    }),
    noOptionsMessage: (styles) => ({ ...styles, fontSize: '0.875rem' }),
  }
}

export const StyledError = styled.p`
  font-size: 14px;
  color: #dc3545;
  margin-top: 4px;
`
